import { useMutation, useQueryClient } from '@tanstack/react-query';

const useDeleteValidateDiscountCode = () => {
  const queryClient = useQueryClient();
  const initial = {};

  const query = useMutation({
    mutationFn: () => initial,
    onSuccess: () => {
      queryClient.setQueryData(['discountCode'], () => initial);
    },
  });

  return query;
};

export default useDeleteValidateDiscountCode;
