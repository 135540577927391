import { useTranslation } from 'next-i18next';

import { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectUser } from '@store/auth/auth.slice';
import isEmpty from 'lodash/isEmpty';

import PencilLightIcon from '@assets/icons/PencilLightIcon';
import Toggle from '@components/elements/Form/Toggle';
import INVOICE_TYPES from '@constants/invoiceTypes';
import {
  selectModuleFakturownia,
  selectMultinational,
  useAppConfigSelector,
} from '@hooks/useAppConfigSelectors';
import useBasketMethods from '@hooks/useBasketMethods';
import { getInitialFormsStateByFields } from '@utils/helpers';

import { initialValues } from './formikData';
import InvoiceModal from './InvoiceModal';
import InvoiceSummary from './InvoiceSummary';
import useFields from './useFields';

const Invoice = ({ invoice, useInvoice, setInvoice }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [isInvoiceModalOpened, setIsInvoiceModalOpened] = useState(false);

  const user = useSelector(selectUser);

  const {
    basketQuery: { data: { invoiceType } = {} },
    isSubscriptionInCart,
  } = useBasketMethods();
  const { allowInvoicesForClient, integrationType, minAmountInvoice } =
    useAppConfigSelector(selectModuleFakturownia);
  const { currencySymbol } = useAppConfigSelector(selectMultinational);
  const fields = useFields(invoiceType);

  const isFullInvoice = invoiceType === INVOICE_TYPES.INVOICE;

  const initialStateValueByFields = fields.map(({ id, type }) => ({
    key: id,
    type,
  }));

  const userInvoiceDataValues = (() => {
    if (!isEmpty(invoice)) {
      return getInitialFormsStateByFields({
        fields: initialStateValueByFields,
        data: invoice,
      });
    } else if (user?.invoiceVatNumber) {
      return getInitialFormsStateByFields({
        fields: initialStateValueByFields,
        data: user,
      });
    }

    return initialValues;
  })();

  const clearCurrentInvoice = () => {
    dispatch(setInvoice({ useInvoice: false, invoice: {} }));
  };

  useEffect(() => {
    if (!useInvoice) return;

    if (
      !userInvoiceDataValues?.invoiceVatNumber ||
      (isFullInvoice &&
        ![
          userInvoiceDataValues?.invoiceCompany,
          userInvoiceDataValues?.invoiceAddressPostCode,
          userInvoiceDataValues?.invoiceAddressCity,
          userInvoiceDataValues?.invoiceAddressStreet,
          userInvoiceDataValues?.invoiceAddressBuildNumber,
        ].every(Boolean))
    ) {
      clearCurrentInvoice();
      setIsInvoiceModalOpened(true);
    }
  }, [invoiceType, useInvoice]);

  const additionalInfoText = t(
    '$*newOrderCreatePage.orderForm.summary.generateInvoice.additionalInfo',
    ''
  );

  if (!allowInvoicesForClient) {
    return null;
  }

  const title =
    invoiceType === INVOICE_TYPES.RECEIPT
      ? t(
          '$*newOrderCreatePage.orderForm.summary.generateReceipt',
          'Generuj paragon z NIP-em'
        )
      : t(
          '$*newOrderCreatePage.orderForm.summary.generateInvoice',
          'Generuj fakturę na firmę'
        );

  const initializeInvoice = () => {
    dispatch(
      setInvoice({
        useInvoice: true,
        invoice: userInvoiceDataValues,
      })
    );
  };

  const checkIfInvoiceShouldInitialize = () => {
    if (!user.invoiceVatNumber) return false;

    return !!(
      !isFullInvoice ||
      (isFullInvoice &&
        user.invoiceCompany &&
        user.invoiceAddressPostCode &&
        user.invoiceAddressCity &&
        user.invoiceAddressStreet &&
        user.invoiceAddressBuildNumber)
    );
  };

  const handleToggle = () => {
    if (useInvoice) {
      clearCurrentInvoice();
    } else if (!checkIfInvoiceShouldInitialize()) {
      setIsInvoiceModalOpened(true);
    } else {
      initializeInvoice();
    }
  };

  useEffect(() => {
    if (checkIfInvoiceShouldInitialize()) {
      initializeInvoice();
    }
  }, [
    user.invoiceCompany,
    user.invoiceVatNumber,
    user.invoiceAddressPostCode,
    user.invoiceAddressCity,
    user.invoiceAddressStreet,
    user.invoiceAddressBuildNumber,
    user.invoiceAddressPlaceNumber,
  ]);

  return (
    <>
      <div>
        <div tw="flex justify-between w-full items-center mb-4">
          <p tw="mb-0">{title}</p>
          <Toggle onChange={handleToggle} value={useInvoice} />
        </div>
        {useInvoice && (
          <div tw="flex justify-between items-start">
            <InvoiceSummary
              integrationType={invoiceType}
              {...userInvoiceDataValues}
            />
            <button type="button" onClick={() => setIsInvoiceModalOpened(true)}>
              <PencilLightIcon tw="w-7 p-1" />
            </button>
          </div>
        )}
      </div>

      <InvoiceModal
        title={title}
        isOpened={isInvoiceModalOpened}
        onClose={() => setIsInvoiceModalOpened(false)}
        setInvoice={setInvoice}
        invoiceType={invoiceType}
        userInvoiceDataValues={userInvoiceDataValues}
        currencySymbol={currencySymbol}
        minAmountInvoice={minAmountInvoice}
        isSubscriptionInCart={isSubscriptionInCart}
        isDynamicInvoiceType={integrationType === INVOICE_TYPES.RECEIPT}
      />
      {!isEmpty(additionalInfoText) && (
        <div
          dangerouslySetInnerHTML={{
            __html: additionalInfoText,
          }}
        />
      )}
    </>
  );
};

export default Invoice;
