import { useQuery, useQueryClient } from '@tanstack/react-query';

const useDiscountCode = (options = {}) => {
  const queryClient = useQueryClient();

  const query = useQuery({
    queryKey: ['discountCode'],
    queryFn: () => queryClient.getQueryData(['discountCode']),
    initialData: () => {
      return queryClient.getQueryData(['discountCode']) ?? {};
    },
    ...options,
  });

  return query;
};

export default useDiscountCode;
