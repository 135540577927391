import { useTranslation } from 'next-i18next';

import { useDispatch, useSelector } from 'react-redux';
import { selectUser } from '@store/auth/auth.slice';
import { Form, Formik } from 'formik';
import isEmpty from 'lodash/isEmpty';
import tw from 'twin.macro';

import Alert from '@components/elements/Alert';
import Button from '@components/elements/Button';
import InputsIndex from '@components/elements/Form/InputsIndex';
import INPUT_IDS from '@constants/inputs/invoiceInputs';
import {
  selectMultinational,
  useAppConfigSelector,
} from '@hooks/useAppConfigSelectors';
import useMediaQuery from '@hooks/useMediaQuery';
import { down } from '@utils/screens';

import { useValidationSchema } from './formikData';
import useFields from './useFields';

const InvoiceForm = ({
  closeModal,
  setInvoice,
  invoiceType,
  userInvoiceDataValues,
}) => {
  const { t } = useTranslation();
  const user = useSelector(selectUser);

  const hasUserInvoiceData = !!user.invoiceVatNumber;

  const dispatch = useDispatch();
  const isDown500 = useMediaQuery(down('500px'), true);
  const { supportedRegions = [] } = useAppConfigSelector(selectMultinational);

  const validationSchema = useValidationSchema(invoiceType);
  const fields = useFields(invoiceType);

  const handleFormikSubmit = (values, { setSubmitting }) => {
    dispatch(setInvoice({ useInvoice: true, invoice: values }));

    setSubmitting(false);
    closeModal();
  };

  const postCodeInputMask =
    Object.values(supportedRegions ?? {})?.length === 1
      ? Object.values(supportedRegions)[0]?.postCodeMask
      : null;

  return (
    <div>
      <Formik
        initialValues={userInvoiceDataValues}
        validationSchema={validationSchema}
        onSubmit={handleFormikSubmit}
        enableReinitialize={true}
      >
        {({ status, isSubmitting, isValid, dirty }) => (
          <Form data-cy="invoice-form">
            <div className="row">
              {fields.map(({ id, colClass = 'col-12', ...restProps }) => {
                if (id === INPUT_IDS.ADDRESS_POST_CODE) {
                  return (
                    <div className={colClass} key={id}>
                      <InputsIndex
                        id={id}
                        mask={
                          !isEmpty(postCodeInputMask)
                            ? postCodeInputMask[0]
                            : null
                        }
                        {...restProps}
                      />
                    </div>
                  );
                }

                if (id === INPUT_IDS.TYPE && !dirty && hasUserInvoiceData) {
                  return null;
                }

                return (
                  <div className={colClass} key={id}>
                    <InputsIndex id={id} {...restProps} />
                  </div>
                );
              })}
            </div>

            {status?.apiErrorMessage && (
              <Alert styles={{ css: tw`mt-4` }}>
                {/* i18next-extract-disable-next-line */}
                {t(`$*error.api.${status.apiErrorMessage}`)}
              </Alert>
            )}
            <div tw="mt-5 flex justify-end flex-wrap">
              <Button
                type="submit"
                isLoading={isSubmitting}
                disabled={isSubmitting || !isValid || !dirty}
                styles={{
                  button: [
                    tw`w-full justify-center`,
                    isDown500 && tw`justify-center flex-auto`,
                  ],
                }}
                data-cy="invoice-form__submit"
              >
                {t('$*common.save', 'Zapisz')}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default InvoiceForm;
