import * as Yup from 'yup';

import INPUT_IDS from '@constants/inputs/discountCodeInputs';

export const useInitialValues = ({ useDiscountCode, discountCode }) => {
  if (useDiscountCode) {
    return {
      [INPUT_IDS.CODE]: discountCode?.code,
    };
  }

  return {
    [INPUT_IDS.CODE]: '',
  };
};

export const useValidationSchema = () => {
  return Yup.object({
    [INPUT_IDS.CODE]: Yup.string(),
  });
};
