import { useTranslation } from 'next-i18next';

import { useEffect } from 'react';
import clsx from 'clsx';
import { useFormikContext } from 'formik';
import { isEmpty } from 'lodash';
import tw from 'twin.macro';

import BinIcon from '@assets/icons/BinIcon';
import Button from '@components/elements/Button';
import InputsIndex from '@components/elements/Form/InputsIndex';
import INPUT_IDS from '@constants/inputs/discountCodeInputs';
import useFormikErrors from '@hooks/useFormikErrors';

import useFields from './useFields';

const DiscountCodeForm = ({
  useDiscountCode,
  handleRemoveUseDiscountCode,
  currentDiet,
  discountCode,
}) => {
  const { t } = useTranslation();

  const { values, isSubmitting, resetForm } = useFormikContext();

  const { hasError, hasApiFieldError } = useFormikErrors(INPUT_IDS.CODE);

  const fields = useFields({ readOnly: useDiscountCode });

  useEffect(() => {
    if (
      !isEmpty(discountCode?.diets) &&
      !discountCode?.diets?.includes(currentDiet?.dietId) &&
      values.code.length > 0
    ) {
      handleRemoveUseDiscountCode(resetForm);
    }
  }, [currentDiet, discountCode]);

  return (
    <div
      className={clsx((hasApiFieldError || hasError) && 'is-invalid')}
      tw="grid grid-cols-[1fr] auto-cols-auto grid-rows-[auto auto] gap-y-2 gap-x-4"
    >
      <label>{t('$*discount.code', 'Kod rabatowy')}</label>
      {fields.map(({ id, ...restProps }) => {
        return (
          <div key={id} tw="relative col-start-1">
            <InputsIndex id={id} {...restProps} />
            {useDiscountCode && (
              <Button
                type="button"
                color="error"
                variant="text"
                onClick={() => handleRemoveUseDiscountCode(resetForm)}
                styles={{
                  button: [
                    tw`absolute p-2 m-0 -translate-y-1/2 top-1/2 right-2`,
                  ],
                }}
                data-cy="discount-code-form__cancel"
              >
                <BinIcon tw="cursor-pointer w-4" />
              </Button>
            )}
          </div>
        );
      })}
      {!useDiscountCode && (
        <Button
          type="submit"
          variant="outlined"
          isLoading={isSubmitting}
          disabled={isSubmitting}
          styles={{
            button: [tw`self-start col-start-2`],
          }}
          data-cy="discount-code-form__submit"
        >
          {t('$*components.discountCode.load', 'Wczytaj')}
        </Button>
      )}
    </div>
  );
};

export default DiscountCodeForm;
