import { Fragment } from 'react';
import isEmpty from 'lodash/isEmpty';
import tw, { css } from 'twin.macro';

import { cssMerge } from '@utils/styleHelpers';

const toggleStyles = css`
  &:checked ~ span:first-of-type {
    transform: translateX(100%);
    ${tw`border-primary`};
  }

  &:checked ~ span:last-of-type {
    ${tw`bg-primary`};
  }
`;

const Toggle = ({
  label,
  styles = {},
  value = false,
  required = false,
  disabled = false,
  ...checkboxProps
}) => {
  const twStyle = {
    label: cssMerge({
      defaultCss: [
        tw`flex items-center cursor-pointer focus-visible:(ring-primary)`,
        disabled ? tw`cursor-not-allowed opacity-70` : tw`cursor-pointer`,
      ],
      ...styles?.label,
    }),
    input: cssMerge({
      defaultCss: [toggleStyles],
      ...styles?.input,
    }),
    span: cssMerge({
      defaultCss: tw`ml-2 cursor-pointer`,
      ...styles?.span,
    }),
    bullet: cssMerge({
      defaultCss: tw`absolute z-10 flex items-center justify-center w-6 h-6 transition-transform duration-300 ease-in-out bg-white border-2 border-gray-1 rounded-full`,
      ...styles?.bullet,
    }),
    slider: cssMerge({
      defaultCss: tw`absolute top-0 left-0 w-full h-full transition-colors duration-300 bg-gray-1 rounded-full`,
      ...styles?.slider,
    }),
  };

  return (
    <Fragment>
      <div tw="flex items-center">
        <label css={twStyle.label}>
          <div
            css={[
              tw`relative shrink-0 inline-block w-12 h-6`,
              label && tw`mr-2`,
            ]}
          >
            <input
              tw="hidden"
              type="checkbox"
              css={twStyle.input}
              checked={Array.isArray(value) ? value?.includes('on') : value}
              disabled={disabled}
              {...checkboxProps}
            />
            <span css={twStyle.bullet} />
            <span css={twStyle.slider} />
          </div>
          {!isEmpty(label) && (
            <span css={twStyle.span}>
              {label} {required && '*'}
            </span>
          )}
        </label>
      </div>
    </Fragment>
  );
};

export default Toggle;
