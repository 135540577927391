import { useTranslation } from 'next-i18next';

import { useMemo } from 'react';
import { useFormikContext } from 'formik';
import tw from 'twin.macro';

import INPUT_IDS from '@constants/inputs/discountCodeInputs';

const useFields = ({ readOnly }) => {
  const { t } = useTranslation();
  const formik = useFormikContext();

  const formFields = useMemo(() => {
    const fields = [
      {
        formGroupProps: { styles: { css: tw`mb-0 md:mb-0` } },
        id: INPUT_IDS.CODE,
        type: 'text',
        placeholder: t(
          '$*input.discountCode.placeholder',
          'Wpisz kod rabatowy'
        ),
        required: false,
        readOnly,
        onChange: ({ target }) => {
          const newValue = target.value.toUpperCase();
          formik.setStatus({
            apiFieldErrors: {},
          });
          formik.setFieldValue(target.name, newValue);
        },
      },
    ];

    return fields;
  }, [t, readOnly]);

  return formFields;
};

export default useFields;
