import { Trans, useTranslation } from 'next-i18next';

import INPUT_IDS from '@constants/inputs/invoiceInputs';
import INVOICE_TYPES from '@constants/invoiceTypes';

import InfoIconTooltip from '../InfoIconTooltip';

const useFields = integrationType => {
  const { t } = useTranslation();

  const formFields = [
    {
      id: INPUT_IDS.COMPANY_NAME,
      type: 'text',
      label: t('$*input.invoiceCompany.label', 'Nazwa firmy'),
      required: true,
    },
    {
      id: INPUT_IDS.VAT_NUMBER,
      type: 'text',
      label: t('$*input.invoiceVatNumber.label', 'NIP'),
      required: true,
    },
    {
      colClass: 'sm:col-5',
      id: INPUT_IDS.ADDRESS_POST_CODE,
      type: 'text',
      label: t('$*input.invoiceAddressPostCode.label', 'Kod pocztowy'),
      required: true,
    },
    {
      colClass: 'sm:col-7',
      id: INPUT_IDS.ADDRESS_CITY,
      type: 'text',
      label: t('$*input.invoiceAddressCity.label', 'Miasto'),
      required: true,
    },
    {
      id: INPUT_IDS.ADDRESS_STREET,
      type: 'text',
      label: t('$*input.invoiceAddressStreet.label', 'Ulica'),
      required: true,
    },
    {
      colClass: 'col-6',
      id: INPUT_IDS.ADDRESS_BUILD_NUMBER,
      type: 'text',
      label: t('$*input.invoiceAddressBuildNumber.label', 'Nr budynku'),
      required: true,
    },
    {
      colClass: 'col-6',
      id: INPUT_IDS.ADDRESS_PLACE_NUMBER,
      type: 'text',
      label: t('$*input.invoiceAddressPlaceNumber.label', 'Nr lokalu'),
      required: false,
    },
    {
      id: INPUT_IDS.TYPE,
      type: 'checkbox',
      label: (
        <div tw="flex items-center">
          <span tw="mr-2">
            {t(
              '$*input.invoiceType.saveForFuture',
              'Zachowaj dane do faktury dla przyszłych zamówień'
            )}
          </span>
          <InfoIconTooltip
            content={
              <Trans
                i18nKey="$*input.invoiceType.tooltip.content"
                defaults='Dane z formularza zostaną zapisane jako <b>"Dane do faktury"</b> w <b>"Ustawieniach konta"</b>.<br/> Zaznacz to pole jeśli chcesz zapisać bieżące dane.'
                parent="p"
                components={{ b: <b />, br: <br /> }}
              />
            }
          />
        </div>
      ),
      required: false,
    },
  ];

  switch (integrationType) {
    case INVOICE_TYPES.RECEIPT:
      return formFields.filter(({ id }) => id === INPUT_IDS.VAT_NUMBER);
    case INVOICE_TYPES.INVOICE:
      return formFields;
    default:
      return formFields;
  }
};

export default useFields;
