import { useTranslation } from 'next-i18next';

import { useDispatch, useSelector } from 'react-redux';
import { selectUser } from '@store/auth/auth.slice';

import {
  selectModuleMoneyBox,
  selectMultinational,
  useAppConfigSelector,
} from '@hooks/useAppConfigSelectors';
import { getIntegerIfIntegerOrFixed } from '@utils/helpers';

import Toggle from '../Form/Toggle';

const PiggyBankPoints = ({
  updateOrderBasketModify,
  useMoneyBox,
  setUseMoneyBox,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { currencySymbol } = useAppConfigSelector(selectMultinational);
  const { moneyBox = 0 } = useSelector(selectUser);
  const { shortName, forPoints, cashValue } =
    useAppConfigSelector(selectModuleMoneyBox);

  const pointsToCashRate = forPoints !== 0 ? cashValue / forPoints : 0;
  const moneyBoxPointsCash = getIntegerIfIntegerOrFixed(
    moneyBox * pointsToCashRate
  );

  const handleUseMoneyBox = () => {
    dispatch(setUseMoneyBox(true));
    updateOrderBasketModify({ payload: { useMoneybox: true } });
  };

  const handleCancelUseMoneyBox = () => {
    dispatch(setUseMoneyBox(false));
    updateOrderBasketModify({ payload: { useMoneybox: false } });
  };

  const handleToggle = e => {
    if (e.target.checked) {
      handleUseMoneyBox();
    } else {
      handleCancelUseMoneyBox();
    }
  };

  if (!moneyBox || moneyBox === 0) {
    return null;
  }

  return (
    <div tw="border-b pb-4 mb-4 lg:(pb-7 mb-7)">
      <div tw="flex justify-between mb-2">
        {t(
          '$*newOrderCreatePage.orderForm.summary.useMoneyBox',
          'Wykorzystaj punkty ze skarbonki'
        )}
        <Toggle onChange={handleToggle} value={useMoneyBox} />
      </div>
      <div tw="flex justify-between text-sm">
        <div>
          {t(
            '$*newOrderCreatePage.orderForm.summary.moneyBoxValue',
            'Wartość skarbonki'
          )}
          :
        </div>

        <div>
          {`${moneyBox} ${
            shortName || t('$*common.pointAbbreviation', 'pkt')
          } / ${moneyBoxPointsCash} ${currencySymbol}`}
        </div>
      </div>
    </div>
  );
};

export default PiggyBankPoints;
