const PencilLightIcon = ({ title = '', ...props }) => (
  <svg
    {...props}
    viewBox="0 0 18 19"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    {title && <title>{title}</title>}
    <path d="M1.39735 15.0085H1.49877L6.39174 13.9437C6.48704 13.9279 6.57536 13.8837 6.64524 13.817L14.9353 5.52684C15.5409 4.92255 15.8788 4.10062 15.8734 3.24517C15.8745 2.38306 15.5377 1.55488 14.9353 0.93812C14.3311 0.332522 13.5092 -0.0053611 12.6537 0.000122286C11.7927 -0.00749622 10.9669 0.34116 10.372 0.963499L2.10718 9.2536C2.0343 9.31226 1.98116 9.39189 1.95505 9.48176L0.890307 14.3747C0.86124 14.5504 0.917723 14.7294 1.04243 14.8564C1.13604 14.9519 1.26361 15.0066 1.39735 15.0085ZM12.6537 1.01421C13.8718 1.01411 14.8593 2.00156 14.8594 3.21969C14.8594 3.22818 14.8594 3.23668 14.8593 3.24517C14.8676 3.82548 14.6386 4.38406 14.2255 4.79163L11.1072 1.64795C11.518 1.23971 12.0745 1.01164 12.6537 1.01421ZM10.3973 2.38321L13.5156 5.50151L6.29027 12.7015L3.17198 9.60851L10.3973 2.38321ZM2.76635 10.6226L5.27619 13.1325L2.05647 13.8424L2.76635 10.6226Z" />
    <path d="M17.493 17.7461H0.507043C0.227002 17.7461 0 17.9731 0 18.2531C0 18.5332 0.227002 18.7602 0.507043 18.7602H17.493C17.773 18.7602 18 18.5332 18 18.2531C18 17.9731 17.773 17.7461 17.493 17.7461Z" />
  </svg>
);

export default PencilLightIcon;
