import { useTranslation } from 'next-i18next';

import INVOICE_TYPES from '@constants/invoiceTypes';

const InvoiceSummary = ({
  integrationType,
  invoiceCompany,
  invoiceVatNumber,
  invoiceAddressCity,
  invoiceAddressStreet,
  invoiceAddressPostCode,
  invoiceAddressPlaceNumber,
  invoiceAddressBuildNumber,
}) => {
  const { t } = useTranslation();

  const invoiceVatNumberP = (
    <p tw="mb-0">
      {t('$*input.invoiceVatNumber.label')}: {invoiceVatNumber}
    </p>
  );

  return (
    <div tw="text-sm text-black">
      {integrationType === INVOICE_TYPES.RECEIPT ? (
        invoiceVatNumberP
      ) : (
        <>
          <p tw="mb-0">{invoiceCompany}</p>
          {invoiceVatNumberP}
          <p tw="mb-0">
            {invoiceAddressStreet}{' '}
            {invoiceAddressPlaceNumber
              ? `${invoiceAddressBuildNumber}/${invoiceAddressPlaceNumber}`
              : invoiceAddressBuildNumber}
          </p>
          <p tw="mb-0">
            {invoiceAddressPostCode} {invoiceAddressCity}
          </p>
        </>
      )}
    </div>
  );
};

export default InvoiceSummary;
