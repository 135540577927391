import { useQuery } from '@tanstack/react-query';

import { ENDPOINT } from '@constants/endpoint';
import axios from '@utils/axiosInstance';

const getPaymentCards = async () => {
  const response = await axios.get(ENDPOINT.PAYMENT_CARDS);

  return response?.data?.['hydra:member'];
};

const usePaymentCards = (options = {}) => {
  const query = useQuery({
    queryKey: ['paymentCards'],
    queryFn: getPaymentCards,
    ...options,
  });

  return query;
};

export default usePaymentCards;
