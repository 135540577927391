import { useTranslation } from 'next-i18next';

import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Form, Formik } from 'formik';

import {
  selectModuleConfigClientPanel,
  useAppConfigSelector,
} from '@hooks/useAppConfigSelectors';
import useBasketMethods from '@hooks/useBasketMethods';
import useDeleteValidateDiscountCode from '@hooks/useDeleteValidateDiscountCode';
import useDiscountCodeHook from '@hooks/useDiscountCode';
import { transformApiErrors } from '@services/Api.serviceTS';
import showToast from '@utils/showToast';

import DisabledDiscountCodeInput from './DisabledDiscountCodeInput';
import DiscountCodeForm from './DiscountCodeForm';
import { useInitialValues, useValidationSchema } from './formikData';

const DiscountCode = ({ testMode }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { data: discountCode = {} } = useDiscountCodeHook();

  const {
    currentDiet,
    basketStore: { useDiscountCode },
    commonSetDiscountCode,
    setDiscountCode,
    updateOrderBasketModify,
  } = useBasketMethods();

  const initialValues = useInitialValues({
    useDiscountCode,
    discountCode,
  });
  const validationSchema = useValidationSchema();

  const { allowGrayingOutDiscountCode: disableDiscountCodeOnTestMode } =
    useAppConfigSelector(selectModuleConfigClientPanel);

  const isDiscountCodeInputDisabled = useMemo(
    () => disableDiscountCodeOnTestMode && testMode,
    [testMode]
  );

  const { mutate: deleteDiscountCode } = useDeleteValidateDiscountCode();

  const handleFormikSubmit = (values, { setStatus, setSubmitting }) => {
    const newCode = values.code?.replace(/  +/g, ' ')?.trim();
    commonSetDiscountCode(newCode)
      .then(() => {
        setStatus();
      })
      .catch(err => {
        const error = err?.response?.data ?? {};
        const staticErrorViolations =
          error?.violations?.map(violation => ({
            ...violation,
            propertyPath: 'code',
          })) ?? [];
        const apiFieldErrors = transformApiErrors({
          ...error,
          violations: staticErrorViolations,
        });

        setStatus({
          apiFieldErrors,
        });
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const handleRemoveUseDiscountCode = resetForm => {
    deleteDiscountCode();
    dispatch(
      setDiscountCode({
        useDiscountCode: false,
        discountCode: { '@id': null, value: '' },
      })
    );
    resetForm();

    updateOrderBasketModify({
      payload: {},
      onSuccessCallback: () => {
        showToast(
          t(
            '$*notification.DiscountCodeRemove.success',
            'Rabat został usunięty'
          ),
          { type: 'info' }
        );
      },
    });
  };

  return (
    <>
      {isDiscountCodeInputDisabled ? (
        <DisabledDiscountCodeInput
          content={t(
            '$*input.discountCode.whenUseTestDays',
            'Kody rabatowe i polecające nie działają przy zamówieniu próbnym'
          )}
        />
      ) : (
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleFormikSubmit}
          enableReinitialize={true}
        >
          <Form autoComplete={'OFF'} data-cy="discount-code-form">
            <DiscountCodeForm
              discountCode={discountCode}
              currentDiet={currentDiet}
              useDiscountCode={useDiscountCode}
              handleRemoveUseDiscountCode={handleRemoveUseDiscountCode}
            />
          </Form>
        </Formik>
      )}
    </>
  );
};

export default DiscountCode;
