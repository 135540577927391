import { useTranslation } from 'next-i18next';

import tw from 'twin.macro';

import ExclamationIcon from '@assets/icons/ExclamationIcon';
import Modal from '@components/elements/Modal/Modal';
import INVOICE_TYPES from '@constants/invoiceTypes';

import InvoiceForm from './InvoiceForm';

const InvoiceModal = ({
  title,
  onClose,
  isOpened,
  currencySymbol,
  minAmountInvoice,
  setInvoice,
  invoiceType,
  userInvoiceDataValues,
  isDynamicInvoiceType,
  isSubscriptionInCart = false,
}) => {
  const { t } = useTranslation();

  return (
    <Modal
      isOpen={isOpened}
      onClose={onClose}
      styles={{ wrapper: tw`w-full max-w-lg`, container: tw`z-60` }}
    >
      <Modal.Header>{title}</Modal.Header>
      <Modal.Content>
        {isDynamicInvoiceType && !isSubscriptionInCart && (
          <div tw="flex mb-4">
            <div tw="mt-1 mr-2">
              <ExclamationIcon tw="w-4 text-orange-1" />
            </div>
            <p>
              {invoiceType === INVOICE_TYPES.RECEIPT
                ? t('$*input.invoiceType.dynamicInvoiceNIPReceipt', {
                    defaultValue:
                      'Twoje zamówienie mieści się w kwocie do {{ value }} {{ currencySymbol }}, w związku z czym wygenerowany zostanie paragon NIP. Sprawdź i zapisz dane do faktury.',
                    replace: {
                      value: minAmountInvoice,
                      currencySymbol,
                    },
                  })
                : t('$*input.invoiceType.dynamicInvoiceInvoice', {
                    defaultValue:
                      'Twoje zamówienie mieści się w kwocie powyżej {{value}} {{currencySymbol}}, w związku z czym wygenerowana zostanie faktura pełna. Sprawdź i zapisz dane do faktury.',
                    replace: {
                      value: minAmountInvoice,
                      currencySymbol,
                    },
                  })}
            </p>
          </div>
        )}
        <InvoiceForm
          closeModal={onClose}
          setInvoice={setInvoice}
          invoiceType={invoiceType}
          userInvoiceDataValues={userInvoiceDataValues}
        />
      </Modal.Content>
    </Modal>
  );
};

export default InvoiceModal;
