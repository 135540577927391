const ExclamationIcon = ({ title = '', ...props }) => (
  <svg
    {...props}
    fill="currentColor"
    viewBox="0 0 512 512"
    xmlns="http://www.w3.org/2000/svg"
  >
    {title && <title>{title}</title>}
    <path d="M256-.003C114.729-.003.034 114.691.034 255.963.034 397.234 114.729 511.928 256 511.928c141.271 0 255.966-114.694 255.966-255.965C511.966 114.691 397.271-.003 256-.003zm0 40c119.195 0 215.966 96.771 215.966 215.966 0 119.194-96.771 215.965-215.966 215.965-119.195 0-215.966-96.771-215.966-215.965 0-119.195 96.771-215.966 215.966-215.966zm0 339.645c11.046 0 20-8.954 20-20V230.849c0-11.046-8.954-20-20-20s-20 8.954-20 20v128.793c0 11.046 8.954 20 20 20zm0-193.283c-14.902 0-27-12.099-27-27 0-14.902 12.098-27 27-27s27 12.098 27 27c0 14.901-12.098 27-27 27z" />
  </svg>
);

export default ExclamationIcon;
