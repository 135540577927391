import * as Yup from 'yup';

import INPUT_IDS from '@constants/inputs/invoiceInputs';
import INVOICE_TYPES from '@constants/invoiceTypes';
import { useDefaultSchemaYup } from '@utils/yupMethods';

export const initialValues = {
  [INPUT_IDS.COMPANY_NAME]: '',
  [INPUT_IDS.VAT_NUMBER]: '',
  [INPUT_IDS.ADDRESS_POST_CODE]: '',
  [INPUT_IDS.ADDRESS_CITY]: '',
  [INPUT_IDS.ADDRESS_STREET]: '',
  [INPUT_IDS.ADDRESS_BUILD_NUMBER]: '',
  [INPUT_IDS.ADDRESS_PLACE_NUMBER]: '',
  [INPUT_IDS.ADDITIONAL_NOTES]: '',
  [INPUT_IDS.TYPE]: false,
};

export const useValidationSchema = integrationType => {
  const { stringRequired, vatNumberRequired } = useDefaultSchemaYup();

  switch (integrationType) {
    case INVOICE_TYPES.RECEIPT:
      return Yup.object({
        [INPUT_IDS.VAT_NUMBER]: vatNumberRequired,
      });
    case INVOICE_TYPES.INVOICE:
      return Yup.object({
        [INPUT_IDS.COMPANY_NAME]: stringRequired,
        [INPUT_IDS.VAT_NUMBER]: vatNumberRequired,
        [INPUT_IDS.ADDRESS_POST_CODE]: stringRequired,
        [INPUT_IDS.ADDRESS_CITY]: stringRequired,
        [INPUT_IDS.ADDRESS_STREET]: stringRequired,
        [INPUT_IDS.ADDRESS_BUILD_NUMBER]: stringRequired,
      });
    default:
      return null;
  }
};
